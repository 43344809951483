import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SlickSlider from 'react-slick'
import Fade from '@material-ui/core/Fade'
import { useInView } from 'react-intersection-observer'

import slickCss from './slick.css'
import slickThemeCss from './slick-theme.css'
import muiTheme from '~/styles/mui-theme'

const heightCss = `
height: 350px;
@media (min-width: 641px) {
	height: 400px;
}
@media (min-width: 769px) {
	max-height: 800px;
	min-height: 300px;
	height: 70vh;
}
`

const StyledWrapper = styled.div`
	${slickCss}
	${slickThemeCss}
	${heightCss}
`

const StyledSlider = styled(SlickSlider)`
	.slick-slide {
		${heightCss}

		> div {
			width: 100%;
			height: 100%;
		}
	}

	.slick-dots,
	.slick-arrow {
		z-index: 10;
	}
	.slick-prev,
	.slick-next {
		&,
		&:hover {
			font-size: 50px;
			color: #fff;
			line-height: 0;
		}
	}
	.slick-prev {
		left: 15px;
	}
	.slick-next {
		right: 15px;
	}

	.slick-dots {
		bottom: 25px;
		li {
			cursor: default;
		}
		button {
			position: relative;
			padding: 10px;
			font-size: 0;
			line-height: 0;
			&:before {
				display: none;
			}
			&:after {
				position: absolute;
				display: block;
				overflow: hidden;
				left: 5px;
				top: 5px;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background-color: rgba(255, 255, 255, 0.75);
				content: '';
			}
		}
		.slick-active {
			button:after {
				background-color: rgba(255, 255, 255, 1);
			}
		}
	}
`

const Arrow = styled.span`
	& {
		display: block;
		font-family: ${muiTheme.typography.h1.fontFamily};
		font-weight: 700;
		font-size: 40px;
		padding: 10px;
		cursor: pointer;
		width: auto;
		height: auto;

		&,
		&:hover {
			line-height: 1;
			color: #fff;
		}

		&:before {
			display: none;
		}
	}
`

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	lazyLoad: false,
	nextArrow: <Arrow>{'>'}</Arrow>,
	prevArrow: <Arrow>{'<'}</Arrow>
}

interface Props {
	lazy?: boolean
}

const ImageSlider: FunctionComponent<Props> = ({ children, lazy, ...rest }) => {
	const [ref, inView] = lazy
		? useInView({
				triggerOnce: true,
				rootMargin: '100px 0px'
		  })
		: [null, true]

	return (
		<StyledWrapper ref={ref}>
			{inView ? (
				<Fade in>
					<StyledSlider {...settings} {...rest}>
						{children}
					</StyledSlider>
				</Fade>
			) : null}
		</StyledWrapper>
	)
}

export default ImageSlider
