import React, { FunctionComponent } from 'react'
import { graphql, PageProps } from 'gatsby'
import styled, { css } from 'styled-components'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import Page from '~/components/Page'
import MarkdownHTML from '~/components/MarkdownHTML'
import { ContentfulCampervan, ContentfulTextBlock, ContentfulImage, ContentfulRentalList } from '~/types/graphql'
import ImageSlider from '~/components/ImageSlider'
import GatsbyBackgroundImage from '~/components/GatsbyBackgroundImage'
import muiTheme from '~/styles/mui-theme'
import IndexLayout, { IndexLayoutDataProps } from '../layouts/IndexLayout'

import surfboardsSvgSrc from '../../static/svg/illustrations/rental-surfboards.svg'
import bodyboardSvgSrc from '../../static/svg/illustrations/rental-bodyboard.svg'
import vanOnMoonSvgSrc from '../../static/svg/illustrations/van-on-moon.svg'

const RentalMarkdownHTML = styled(MarkdownHTML)`
	p {
		margin: 0;
	}
`

const surfboardsSvg = (
	<img
		src={surfboardsSvgSrc}
		alt=""
		css={css`
			width: 200px;
			height: 240px;
			padding: 10px;
		`}
	/>
)
const bodyboardSvg = (
	<img
		src={bodyboardSvgSrc}
		alt=""
		css={css`
			width: 200px;
			height: 240px;
			padding: 10px;
		`}
	/>
)

const OrderedGridItem = styled(Grid)`
	order: 1;
	${muiTheme.breakpoints.up('sm')} {
		order: 10;
	}
`

// locale is provided through PageProps
// @see: https://www.gatsbyjs.org/docs/gatsby-internals-terminology/#pagecontext
export const query = graphql`
	query($locale: String) {
		...ContentfulMetaData
		...FooterData

		contentfulCampervanList(contentfulid: { eq: "available-campervans" }, node_locale: { eq: $locale }) {
			campervans {
				name
				description {
					childMarkdownRemark {
						html
					}
				}
				gallery {
					focusArea
					image {
						id
						localFile {
							childImageSharp {
								mobile: fluid(
									quality: 80
									maxWidth: 768
									maxHeight: 768
									srcSetBreakpoints: [500, 768]
									cropFocus: SOUTH
									fit: COVER
									jpegProgressive: false
								) {
									...GatsbyImageSharpFluid_withWebp
								}
								desktop: fluid(quality: 80, maxWidth: 1980, srcSetBreakpoints: [768, 1280, 1366], jpegProgressive: false) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		}

		campervansInfo: contentfulTextBlock(contentfulid: { eq: "campervans-info" }, node_locale: { eq: $locale }) {
			description {
				childMarkdownRemark {
					html
				}
			}
			title
		}

		rentalEquipmentImage: contentfulImage(contentfulid: { eq: "rental-equipment" }) {
			image {
				localFile {
					childImageSharp {
						mobile: fluid(
							quality: 80
							maxWidth: 768
							maxHeight: 768
							srcSetBreakpoints: [500, 768]
							cropFocus: CENTER
							fit: COVER
							jpegProgressive: false
						) {
							...GatsbyImageSharpFluid_withWebp
						}
						desktop: fluid(quality: 80, maxWidth: 1980, srcSetBreakpoints: [768, 1280, 1366], jpegProgressive: false) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		}

		surfboardRentals: contentfulRentalList(contentfulid: { eq: "surfboards" }, node_locale: { eq: $locale }) {
			list {
				id
				description {
					childMarkdownRemark {
						html
					}
				}
				cost {
					childMarkdownRemark {
						html
					}
				}
			}
			extra {
				childMarkdownRemark {
					html
				}
			}
		}

		otherRentals: contentfulRentalList(contentfulid: { eq: "other" }, node_locale: { eq: $locale }) {
			list {
				id
				description {
					childMarkdownRemark {
						html
					}
				}
				cost {
					childMarkdownRemark {
						html
					}
				}
			}
			extra {
				childMarkdownRemark {
					html
				}
			}
		}
	}
`

type Props = {
	data: {
		contentfulCampervanList: {
			campervans: ContentfulCampervan[]
		}
		campervansInfo: ContentfulTextBlock
		rentalEquipmentImage: ContentfulImage
		surfboardRentals: ContentfulRentalList
		otherRentals: ContentfulRentalList
	}
} & IndexLayoutDataProps &
	PageProps

const CampervansPage: FunctionComponent<Props> = ({ location, data, pageContext }) => {
	const {
		contentfulCampervanList: { campervans },
		campervansInfo,
		rentalEquipmentImage,
		surfboardRentals,
		otherRentals
	} = data

	return (
		<IndexLayout data={data} location={location} pageContext={pageContext}>
			<Page>
				{campervans.map((campervan, index) => {
					const first = index === 0
					const {
						name,
						description: {
							childMarkdownRemark: { html }
						},
						gallery
					} = campervan

					return (
						<div key={name}>
							<Container maxWidth="md">
								<Box mt={first ? 6 : 10} mb={6}>
									<Typography variant="h1" gutterBottom align="center">
										{name}
									</Typography>
									<MarkdownHTML textAlign="center" html={html} />
								</Box>
							</Container>
							{gallery ? (
								<ImageSlider lazy={!first}>
									{gallery.map((contentfulImage) => (
										<GatsbyBackgroundImage
											objectPosition={contentfulImage.focusArea}
											css={css`
												width: 100%;
												height: 100%;
											`}
											key={contentfulImage.image.id!}
											fluid={{
												mobile: contentfulImage.image.localFile.childImageSharp.mobile!,
												desktop: contentfulImage.image.localFile.childImageSharp.desktop!
											}}
										/>
									))}
								</ImageSlider>
							) : (
								''
							)}
						</div>
					)
				})}

				<Container maxWidth="md">
					<Box mt={5} mb={7}>
						<Typography variant="h1" component="h2" gutterBottom align="center">
							{campervansInfo.title}
						</Typography>
						<MarkdownHTML textAlign="center" html={campervansInfo.description.childMarkdownRemark.html} />
					</Box>
				</Container>

				<GatsbyBackgroundImage
					css={css`
						height: 50vh;
						max-height: 700px;
						min-height: 500px;
						@media screen (min-width: 500px) {
							height: 60vh;
						}
					`}
					fluid={{
						mobile: rentalEquipmentImage.image.localFile.childImageSharp.mobile!,
						desktop: rentalEquipmentImage.image.localFile.childImageSharp.desktop!
					}}
				/>

				<Box mb={4} mt={6}>
					<Container maxWidth="md">
						<Grid container>
							<OrderedGridItem container item xs={12} sm={4} justify="center">
								{surfboardsSvg}
							</OrderedGridItem>

							<Grid item xs={12} sm={8}>
								{surfboardRentals.list.map(({ id, description, cost }) => (
									<Grid container spacing={3} key={id}>
										<Grid item xs={7}>
											<RentalMarkdownHTML html={description.childMarkdownRemark.html} />
										</Grid>
										<Grid item xs={5}>
											<RentalMarkdownHTML html={cost.childMarkdownRemark.html} />
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
						{surfboardRentals.extra ? (
							<Box my={2}>
								<RentalMarkdownHTML html={surfboardRentals.extra.childMarkdownRemark.html} />
							</Box>
						) : null}
					</Container>
				</Box>

				<Box my={6}>
					<Container maxWidth="md">
						<Grid container>
							<OrderedGridItem container item xs={12} sm={4} justify="center">
								{bodyboardSvg}
							</OrderedGridItem>

							<Grid item xs={12} sm={8}>
								{otherRentals.list.map(({ id, description, cost }) => (
									<Grid container spacing={3} key={id}>
										<Grid item xs={7}>
											<RentalMarkdownHTML html={description.childMarkdownRemark.html} />
										</Grid>
										<Grid item xs={5}>
											<RentalMarkdownHTML html={cost.childMarkdownRemark.html} />
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
						{otherRentals.extra ? (
							<Box my={2}>
								<RentalMarkdownHTML html={otherRentals.extra.childMarkdownRemark.html} />
							</Box>
						) : null}
					</Container>
				</Box>
			</Page>
		</IndexLayout>
	)
}

export default CampervansPage
